<template>
  <section class="open-pay-box-container d-flex flex-column align-items-center">
    <b-row class="mt-2">
      <b-col>
        <feather-icon
          size="100"
          icon="InboxIcon"
        />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col
        class="text-center"
        :class="`text-danger`"
      >
        <p
          class="h2"
          :class="`text-${stepDescription[step].color}`"
        >
          {{ stepDescription[step].title }}
        </p>
        <p class="h5">
          {{ stepDescription[step].description }}
        </p>
      </b-col>
    </b-row>

    <b-row class="mt-auto mb-4 open-pay-box-form">
      <b-col>
        <FormulateForm
          name="formOpenPayBox"
          @submit="onOpenPdv"
        >
          <FormulateInput
            v-if="step === 1"
            id="paybox_open-pdv_initial_balance"
            v-model="initialBalance"
            class="input-size"
            type="text-number"
            :precision="2"
            currency="R$"
            :label="$t('Disponível em caixa')"
          />

          <e-button
            v-if="step === 0"
            id="paybox_open-btn_open_paybox"
            variant="outline-primary"
            block
            size="lg"
            icon="printer"
            :text="$t('Reimprimir fechamento')"
            title="Imprime o último fechamento realizado neste caixa"
            @click="onPrintLastClosing"
          />
          <br>
          <br>

          <e-button
            id="paybox_open-btn_open_paybox"
            variant="primary"
            block
            size="lg"
            :text="$t('Abrir Caixa')"
            type="submit"
          />
          <e-button
            id="paybox_open-btn_cancel"
            block
            size="lg"
            :text="step === 0 && canAccessErp ? $t('Voltar ao ERP') : $t('Cancelar')"
            @click="onBack"
          />
        </FormulateForm>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { hasErpAccess } from '@/auth/utils'
import {
  loadingOverlay,
  pdvBrowserNotAllowedConfiguration,
  payboxConfiguration,
  formulateHelper,
} from '@/mixins'
import { mapActions, mapState, mapGetters } from 'vuex'
import isElectron from 'is-electron'

export default {
  components: { EButton, BRow, BCol },

  mixins: [loadingOverlay, pdvBrowserNotAllowedConfiguration, payboxConfiguration, formulateHelper],

  data() {
    return {
      step: 0,
      initialBalance: 0,
    }
  },

  computed: {
    ...mapState('pages/pdv', ['cashBookDetail']),
    ...mapGetters('pages/pdv/payBoxConfiguration', ['thermalPrinterAgentConfigPayload']),

    canAccessErp() {
      return hasErpAccess()
    },

    stepDescription() {
      return {
        0: {
          title: this.$t('Caixa Fechado'),
          color: 'danger',
        },
        1: {
          title: this.$t('Abertura de Caixa'),
          description: this.$t(
            'Confira o valor atual do troco na gaveta e o informe para abrir o caixa'
          ),
          color: 'primary',
        },
      }
    },
  },

  async mounted() {
    try {
      if (isElectron()) {
        const canNavigate = await window.electronAPP.canNavigateToPdv()
        if (!canNavigate) {
          window.close()
          return
        }
      }
      this.showLoadingOverlay(this.$t('Carregando dados do caixa'))
      await this.stFetchCashBookDetail()

      this.initialBalance = this.cashBookDetail.oppeningBalance || 0
    } catch (error) {
      if (error?.response?.status === 404) {
        this.showError({ title: 'Aviso', error })
      } else {
        this.showError({ error })
      }
    } finally {
      this.hideLoadingOverlay()
    }
  },

  methods: {
    ...mapActions('pages/pdv', {
      stOpenPdv: 'openPdv',
      stFetchCashBookDetail: 'fetchCashBookDetail',
    }),
    ...mapActions('pages/pdv/payBoxPrint', ['printOpenCashBook', 'printCloseCashBook']),
    async onOpenPdv() {
      if (this.step === 0) {
        try {
          window.electronAPI.openCashDrawer(this.thermalPrinterAgentConfigPayload)
        } catch (err) {
          console.error(err)
        }

        this.step = 1
        this.focusInput('#paybox_open-pdv_initial_balance')
        return
      }

      const confirm = await this.confirmHtml({
        title: 'Confirmar abertura?',
        html: `Valor em caixa é: <br> <b>${this.$options.filters.currency(
          this.initialBalance
        )}</b>`,
        focusCancel: true,
      })

      if (confirm) {
        try {
          const openData = await this.stOpenPdv({ balance: this.initialBalance })
          await this.printOpenCashBook({ openData })

          await this.mxPdvRouteRedirect()
        } catch (error) {
          this.showError({ error })
        }
      }
    },

    async onPrintLastClosing() {
      const confirm = await this.confirm({
        title: 'Deseja imprimir o último fechamento ?',
        text: ' ',
      })

      if (confirm) {
        this.showLoadingOverlay(this.$t('CASH_BOOK.PRINTING_CLOSING_RECEIPT'))
        try {
          await this.printCloseCashBook({ lastForCurrentPaybox: true })
        } finally {
          this.hideLoadingOverlay()
        }
      }
    },
    onBack() {
      if (this.step === 1) {
        this.step = 0
        return
      }

      if (this.canAccessErp) {
        this.$router.push({ name: 'home' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.open-pay-box-container {
  margin: 0 auto;
  max-width: 900px;
  height: 90vh;
}

.open-pay-box-form {
  width: 350px;
}

.input-size {
  font-size: 1.5rem;
}
</style>
