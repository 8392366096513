var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "open-pay-box-container d-flex flex-column align-items-center",
    },
    [
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c(
            "b-col",
            [_c("feather-icon", { attrs: { size: "100", icon: "InboxIcon" } })],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", { staticClass: "text-center", class: "text-danger" }, [
            _c(
              "p",
              {
                staticClass: "h2",
                class: "text-" + _vm.stepDescription[_vm.step].color,
              },
              [_vm._v(" " + _vm._s(_vm.stepDescription[_vm.step].title) + " ")]
            ),
            _c("p", { staticClass: "h5" }, [
              _vm._v(
                " " + _vm._s(_vm.stepDescription[_vm.step].description) + " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-auto mb-4 open-pay-box-form" },
        [
          _c(
            "b-col",
            [
              _c(
                "FormulateForm",
                {
                  attrs: { name: "formOpenPayBox" },
                  on: { submit: _vm.onOpenPdv },
                },
                [
                  _vm.step === 1
                    ? _c("FormulateInput", {
                        staticClass: "input-size",
                        attrs: {
                          id: "paybox_open-pdv_initial_balance",
                          type: "text-number",
                          precision: 2,
                          currency: "R$",
                          label: _vm.$t("Disponível em caixa"),
                        },
                        model: {
                          value: _vm.initialBalance,
                          callback: function ($$v) {
                            _vm.initialBalance = $$v
                          },
                          expression: "initialBalance",
                        },
                      })
                    : _vm._e(),
                  _vm.step === 0
                    ? _c("e-button", {
                        attrs: {
                          id: "paybox_open-btn_open_paybox",
                          variant: "outline-primary",
                          block: "",
                          size: "lg",
                          icon: "printer",
                          text: _vm.$t("Reimprimir fechamento"),
                          title:
                            "Imprime o último fechamento realizado neste caixa",
                        },
                        on: { click: _vm.onPrintLastClosing },
                      })
                    : _vm._e(),
                  _c("br"),
                  _c("br"),
                  _c("e-button", {
                    attrs: {
                      id: "paybox_open-btn_open_paybox",
                      variant: "primary",
                      block: "",
                      size: "lg",
                      text: _vm.$t("Abrir Caixa"),
                      type: "submit",
                    },
                  }),
                  _c("e-button", {
                    attrs: {
                      id: "paybox_open-btn_cancel",
                      block: "",
                      size: "lg",
                      text:
                        _vm.step === 0 && _vm.canAccessErp
                          ? _vm.$t("Voltar ao ERP")
                          : _vm.$t("Cancelar"),
                    },
                    on: { click: _vm.onBack },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }